import { Helmet } from 'react-helmet'
import Layout from '../components/MainLayout'
import React from 'react'

export default function About() {
  const title = 'О нас'

  return (
    <Layout siteHeader={false} pageTitle={title}>
      <Helmet>
        <title>BuyMyCar - {title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <div className="container">
        <p>Если Вы не хотите или не знаете как продать автомобиль, у вас нет времени самим заниматься продажей своего авто - обращайтесь к нам.</p>
        <p>
          Наша компания предоставляет услуги по срочному выкупу автомобилей у физических и юридических лиц. Мы работаем на автомобильном рынке уже более 10 лет.
          Выкупаем автомобили в любом состоянии быстро и дорого, выгоднее, чем в trade-in и во многих других компаниях.
        </p>
        <p>
          Все что Вам нужно это оставить заявку на сайте и с Вами свяжется наш специалист, который проведет предварительную оценку вашего авто. Если стоимость
          устроит к Вам подъедет специалист-оценщик, осмотрит автомобиль и Вы на месте заключите договор купли продажи, получите полную стоимость за ваш
          автомобиль наличным или безналичным расчетом.
        </p>
      </div>
    </Layout>
  )
}
